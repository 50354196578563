import React from "react";
const Form = () => {
    return (
<section>
    <h2>FORM</h2>

    <lable>What is your name</lable>
</section>
    )
}

export default Form