import React from "react";
import FadeIn from "react-fade-in";
import Footer from "../../Footer";




const NoScreen = ({clickHandler, setUsersPath}) => {
    return (
        <>
       
        <div className="mt-4">
  <p className="s-decision-tree__paragraph">
  Die primäre Aufgabe von Versicherungen ist die Risikoabsicherung - nicht die Kapitalanlage!
  <br />
<br />
Grundsätzlich sind Versicherungen mit Vorsorge- oder Kapitalanlageprodukten in einem Niedrigzins-Umfeld <strong>nicht sinnvoll</strong>.
<br />
<br />
Im Ergebnis heißt das: Risikolebensversicherungen ja, <strong>Kapitallebensversicherung und Altersvorsorge über Versicherungen nein.</strong>
 </p>



<p onClick={clickHandler}
      data-goto="literature2" className="s-decision-tree__link mb-8">
Literatur
</p>

<div className="md:mt-20 mt-4 flex justify-between items-center absoluteDoubleBtn">
<button onClick={() => setUsersPath(["start"])} className="s-decision-tree__btn--back">
zurück
</button>
</div>

        </div>
      
    
        <Footer literarur={true} />
          </>
    )
}

export default NoScreen