import React, { useEffect, useState } from "react";



const ProgressBar = ({progressBar, progressTitle}) => {
    const [bar, setBar] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setBar(progressBar)
        }, 200);
     
    },[])
    return (
        <div className="s-decision-tree__progress">
            <p className="s-decision-tree__progress__title">
                {progressTitle}
            </p>
            <div className="s-decision-tree__progress__outer">
            <div style={{width: `${bar}%`, transition: 'width 1s ease-in-out'}} class="s-decision-tree__progress__inner"></div>
            </div>
        </div>
    )
}

export default ProgressBar