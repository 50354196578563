import React from "react";

const SideScreen3 = ({clickHandler}) => {
    return (<div className="md:mt-2 mt-4">
    <p className="s-decision-tree__paragraph">
 
    Aus der Risikoforschung sind zwei Erkenntnisse für die <strong>Prognose von Aktienmärkten relevant:</strong>
    <br />
<br />
  
    1. Langfristige Aktienanlagen sind eine "Entscheidung unter Risiko": 
    <br />
<br />
      Die Eintrittswahrscheinlichkeiten sind bekannt. Gute Entscheidungen werden unter Zuhilfenahme der Statistik und Wahrscheinlichkeitsrechnung erzielt.
<br />
<br />
  
2.  Mittelfristige Aktienanlagen sind eine "Entscheidung unter Ungewissheit": 
<br />
<br />
  
  Intuition und kluge Faustregeln führen zu guten Entscheidungen. <strong>Berater mit langjähriger Erfahrung können hier gute Entscheidungen treffen.</strong>
</p>


  <p onClick={clickHandler}
        data-goto="literature3" className="s-decision-tree__link mt-2 mb-4">
  Literatur
  </p>
  
  <div className="md:mt-2 mt-4 flex justify-between items-center absoluteDoubleBtn">
  <button onClick={clickHandler}
        data-goto="step-4" className="s-decision-tree__btn--back">
  zurück
  </button>
  
  <button onClick={clickHandler}
        data-goto="step-5" className="s-decision-tree__btn--next ">
    Weiter
  </button>
  
  </div>
  
          </div>
        )
}

export default SideScreen3