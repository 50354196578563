

const Banner = () => {
    return (
        <div className="s-banner text-white">
            <p className="s-banner__title custom-width">ORIENTIERUNGSWISSEN</p>
        </div>

    )
}

export default Banner