import React from "react";
import Footer from "../../Footer";
import FadeIn from "react-fade-in";


const Literatur2 = ({clickHandler}) => {
    return (
        <>
     
        <div className="mt-4">
 <p className="s-decision-tree__paragraph">
Hier finden Sie weiterführende Informationen zu dem Thema:
   </p>

<div className="flex  flex-col justify-left text-left">
{/* <a href="#" className="s-decision-tree__link mt-4">
Handelsblack
</a>
<a href="#" className="s-decision-tree__link mt-3">
Europäische Zentralbank
</a> */}
<a href="https://www.finanztip.de/sinnvolle-versicherungen/" target="_blank" className="s-decision-tree__link mt-3" style={{textDecoration: "underline"}}>
 Finanztip.de
</a>
<a href="https://www.handelsblatt.com/meinung/kommentare/kommentar-die-kosten-der-lebensversicherer-muessen-runter/27613806.html?ticket=ST-739015-dYgCShDLfNqYfa3ZlzcP-cas01.example.org" target="_blank" className="s-decision-tree__link mt-3" style={{textDecoration: "underline"}}>
Die Kosten der Lebensversicherung
</a>
<p style={{color: "#2860F6"}}>
Handelsblatt: Die Kosten der Lebensversicherung müssen runter (Kommentar)
    </p>
<a href="https://www.handelsblatt.com/finanzen/vorsorge/altersvorsorge-sparen/lebensversicherung-zu-teuer-fuer-eine-gute-rendite-heftige-bafin-kritik-an-fondspolicen-von-lebensversicherern/28188100.html" target="_blank" className="s-decision-tree__link mt-3" style={{textDecoration: "underline"}}>
Bafin-Kritik an Fondspolicen von Lebensversicherern
</a>
<p style={{color: "#2860F6"}}>
Handelsblatt: Zu teuer für eine gute Rendite - Heftige Bafin-Kritik an Fondspolicen von Lebensversicherern
    </p>

</div>

<div className="mt-20 flex justify-between items-center absoluteDoubleBtn">
<button className="s-decision-tree__btn--back" onClick={clickHandler}
      data-goto="step-no">
zurück
</button>


</div>

        </div>
      
        <Footer />
        </>
    )
}

export default Literatur2