import React from "react";
const Thanks = () => {
    return (
<section>
    <h2> Thank you for submitting the form! You should hear back from us in 3
            working days.</h2>
</section>
    )
}

export default Thanks