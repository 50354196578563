import React from 'react';
import FadeIn from "react-fade-in"
import backIcon from "../../images/back.svg"
import resetIcon from "../../images/restart.svg"
import Footer from '../Footer';
import NoScreen from './screens/NoScreen';
import Literatur2 from './screens/Literatur2';
import FinalScreen7 from './screens/FinalScreen7';
import FinalScreen8 from './screens/FinalScreen8';
import FinalScreen5 from './screens/FinalScreen5';
import FinalScreen6 from './screens/FinalScreen6';
import SideScreen3 from './screens/SideScreen3';
import Literatur3 from './screens/Literatur3';
import FinalScreen3 from './screens/FinalScreen3';
import FinalScreen4 from './screens/FinalScreen4';
import FinalScreen1 from './screens/FinalSCreen1';
import FinalScreen2 from './screens/FinalScreen2';

const Button = ({ answer, goto, clickHandler, dataKey, selectedPath }) => {
  const isActive = selectedPath != dataKey && selectedPath != null;
  return (
    <button
      className="s-decision-tree__btn mt-4 flex items-center"
      id="answer"
      onClick={clickHandler}
      data-goto={goto}
      data-key={dataKey}
    >
    {dataKey === 0 ? 
    <span className="mr-4"  onClick={clickHandler}
    data-goto={goto}>
  A
    </span> : dataKey === 1? <span className="mr-4" onClick={clickHandler}
    data-goto={goto}>
 B 
    </span> : dataKey === 2? 

<span className="mr-4" onClick={clickHandler}
    data-goto={goto}>
C
  </span> : dataKey === 3 ?   <span className="mr-4" onClick={clickHandler}
    data-goto={goto}>
  D
    </span> : null
    }
    
  <p onClick={clickHandler}
    data-goto={goto}>{answer}</p>
    </button>
  );
};

const Options = ({ options, clickHandler, selectedPath, setUsersPath, backScreen, finalScreen }) => {
  


  const optionList = () => {
    return options && options.length > 0 && options.map((option, i) => {
      return (
        <FadeIn>
        
        <Button
          clickHandler={clickHandler}
          {...option}
          key={i}
          dataKey={i}
          selectedPath={selectedPath}
        />


        </FadeIn>
     
      );
    });

  };

  const MomentumInvestments = () => {
   
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      return (
        <> 
        <div className="mt-4">
  <p className="s-decision-tree__paragraph">
  Die Wissenschaft sagt, es gibt eine Möglichkeit mit aktiven Investments erfolgreich zu sein.
<br />
<br />
Die Momentum-Strategie beruht auf der Wahrscheinlichkeit, dass positive Entwicklungen von Aktienkursen in der jüngeren Vergangenheit auch auf positive Wertentwicklungen in der Zukunft schließen lassen.  "The trend is your friend".  </p>

<p onClick={clickHandler}
      data-goto="literature1" className="s-decision-tree__link mt-2 mb-4">
Literatur
</p>

<div className="mt-4 flex justify-between items-center absoluteDoubleBtn">
<button onClick={clickHandler}
      data-goto="top-3" className="s-decision-tree__btn--back">
zurück
</button>

<button onClick={clickHandler}
      data-goto="step-4" className="s-decision-tree__btn--next ">
  Weiter
</button>
</div>
      </div>
       
         <Footer literarur={true} />
 
          </>
     
      );
    });
  }

  const Side3 = () => {
   
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      return (
        <>
        <FadeIn>
       <SideScreen3 clickHandler={clickHandler} />
        </FadeIn>
        <Footer literarur={true} />
          </>
      );
    });
  }

  // const FinalSCreen1 = () => {
  //   return options && options.length > 0 && options.slice(0,1).map((option, i) => {
  //     const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
  //   const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //   const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //     return (
  //      <FadeIn>
  //      <FinalScreen tips={tips}
       
  //      tips2={tips2}
  //     tips3={tips3}
 
  //     tips4= {
  //       true
  //     }
  //      frame={tradeRepublicFrame} description="Trade Republic ist ein Berliner Fintech, das ein Wertpapierdepot über eine Smartphone-App anbietet." subtitle="Wertpapierdepot als App" title="Trade Republic" sub logo={tradeRepublicLogo} paragraph="Du möchtest Spaß haben, Zeit mit deinen Investments verbringen und dabei selbstbestimmt handeln." paragraph2="Das kannst du bei einem Neobroker am kostengünstigsten umsetzen." />
    
  //      </FadeIn>
  //     );
  //   });
  // }

  const NoScreenOption = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
  
      return (
       <>
     <NoScreen setUsersPath={setUsersPath} clickHandler={clickHandler}/>
       </>
      );
    });
  }
  const LiteraturScreen2 = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
  
      return (
       <>
     <Literatur2 clickHandler={clickHandler}/>
       </>
      );
    });
  }



  // const FinalSCreen2 = () => {
  //   return options && options.length > 0 && options.slice(0,1).map((option, i) => {
  //     const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
  //   const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //   const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //     return (
  //      <FadeIn>
  //      <FinalScreen tips={tips}
       
  //      tips2={tips2}
  //     tips3={tips3}
 
  //     tips4= {
  //       false
  //     }
  //     paragraph="Du möchtest Spaß haben, Zeit mit deinen Investments verbringen und dabei für deine Entscheidungen Anregungen erhalten. "

  //     logo={consorsBankLogo}
  //     paragraph2={"Das kannst du bei einem Discountbroker am kostengünstigsten umsetzen."}
  //      frame={consorsBankFrame} description="Die Consorbank hat rund 1,5 Millionen Kunden in Deutschland und gehört zu den führenden Direktbanken Europas." subtitle="Günstiger Discountbroker" title="Consorbank" />
    
  //      </FadeIn>
  //     );
  //   });
  // }

//   const FinalSCreen3 = () => {
//     return options && options.length > 0 && options.slice(0,1).map((option, i) => {
//       const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
//     const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
//     const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
//       return (
//        <FadeIn>
//        <FinalScreen tips={tips}
       
//        tips2={tips2}
//       tips3={tips3}
 
//       tips4= {
//         false
//       }
//       paragraph="Du möchtest Spaß haben, Zeit mit deinen Investments verbringen und dich zu deinen Entscheidungen beraten lassen."
//       logo={spardaBankLogo}
//       paragraph2={"Das kannst du mit einem persönlichen Bankberater am besten umsetzen."}
//  description="Bei der Sparda-Bank erhältst du kostengünstigen Zugang zu Beratern, die für dich eine Anlagestrategie entwerfen." subtitle="Genossenschaftsbank  mit  Depot" title="Sparda-bank"  />
    
//        </FadeIn>
//       );
//     });
//   }

  // const FinalSCreen4 = () => {
  //   return options && options.length > 0 && options.slice(0,1).map((option, i) => {
  //     const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
  //   const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //   const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
  //     return (
  //      <FadeIn>
  //      <FinalScreen tips={tips}
       
  //      tips2={tips2}
  //     tips3={tips3}
 
  //     tips4= {
  //       false
  //     }
  //     frame={consorsBankFrame}
  //     paragraph="Bei diesem Modell benötigst du eine Bank und einen Vermögensberater."
  //     logo={consorsBankLogo}
  //     isMulti={true}
  //     secondBankLogo={djeBankLogo}
  //     secondDescription="Ausgehend von Ihren Vorgaben und Wünschen entwickelt die DJE Kapital AG eine für Sie passende Anlagestrategie."
  //     secondTitle="Dje Kapital AG"
  //     secondSubtitle="Vermögensverwaltung"
  //     paragraph2={"Als Bank empfehlen wir dir eine kostengünstige Direktbank."}
  //     paragraph3="Unter den Vermögensberatern hat sich die DJE Kapital AG von Dr. Jens Erhardt mit langjähriger Erfahrung im Markt bewiesen."
  //   description="Die Consorbank hat rund 1,5 Millionen Kunden in Deutschland und gehört zu den führenden Direktbanken Europas."  subtitle="Günstiger Discountbroker" title="Consorbank"  />
    
  //      </FadeIn>
  //     );
  //   });
  // }
  const Final5 = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
    const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
    const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
      return (
       <FadeIn>
       <FinalScreen5 tips={tips}
       
       tips2={tips2}
      tips3={tips3}
 
      tips4= {
        false
      }
   />
       </FadeIn>
      );
    });
  }
  const Final6 = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
    const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
    const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
      return (
       <FadeIn>
       <FinalScreen6 tips={tips}
       
       tips2={tips2}
      tips3={tips3}
 
      tips4= {
        false
      }
   />
       </FadeIn>
      );
    });
  }


  const Final7 = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
    const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
    const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
      return (
       <FadeIn>
       <FinalScreen7 tips={tips}
       
       tips2={tips2}
      tips3={tips3}
 
      tips4= {
        false
      }
   />
       </FadeIn>
      );
    });
  }

  const Final8 = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      const tips = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."]
    const tips2 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
    const tips3 =  ["Lorem ipsum dolor sit amet, consectetur adipiscing"]
      return (
       <FadeIn>
       <FinalScreen8 tips={tips}
       
       tips2={tips2}
      tips3={tips3}
 
      tips4= {
        false
      }
   />
       </FadeIn>
      );
    });
  }


  const Literatur = () => {
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      return (
        <>
        <FadeIn>
        <div className="mt-4">
 <p className="s-decision-tree__paragraph">
 Hier finden Sie weiterführende Informationen zu dem Thema:
   </p>

<div className="flex  flex-col justify-left text-left">
{/* <a href="#" className="s-decision-tree__link mt-4">
Handelsblack
</a>
<a href="#" className="s-decision-tree__link mt-3">
Europäische Zentralbank
</a> */}
<a href="https://www.faz.net/aktuell/finanzen/basiswissen/anlagestrategien-der-momentum-ansatz-116708.html" target="_blank" className="s-decision-tree__link mt-3 underline">
Frankfurter Allgemeine Zeitung (FAZ)
</a>
<a href="https://www.nzz.ch/finanzen/fonds/diese-anlagestrategie-brachte-in-den-letzten-200-jahren-die-groessten-renditen-ld.1485573" target="_blank" className="s-decision-tree__link mt-3 underline">
Neue Zürcher Zeitung
</a>
<a href="https://villiam-public-docs.s3.eu-central-1.amazonaws.com/SSRN-id299107.pdf" target="_blank" className="s-decision-tree__link mt-3 underline">
  Momentum
</a>

<p style={{color: "#2860F6", fontSize: "12px", marginTop: "5px"}}>
Narasimhan Jegadeesh
Sheridan Titman
October 23, 2001
<br />
<br />
Fazit: Es gibt substanzielle Beweise dafür, dass Aktien mit der besten Performance über einen Zeitraum von drei bis zwölf Monaten
weiterhin eine gute Entwicklung über die folgenden drei bis zwölf Monate aufweisen.
Momentum-Handelsstrategien, die dies ausnutzen, waren in den Vereinigten Staaten und in den meisten entwickelten Ländern profitabel.
</p>
</div>

<div className="md:mt-10 mt-20 flex justify-between absoluteDoubleBtn items-center">
<button className="s-decision-tree__btn--back" onClick={clickHandler}
      data-goto="momentum-investements">
zurück
</button>


</div>

        </div>
        </FadeIn>
        <Footer literarur={true} />
        </>
     
      );
    });
  }

  const LiteraturScreen3 = () => {
    
   
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
      return (
        <>
        <FadeIn>
       <Literatur3 clickHandler={clickHandler}/>
        </FadeIn>
        <Footer literarur={true} />
        </>
     
      );
    });
  }

  const backButton = () => {
     
    return options && options.length > 0 && options.slice(0,1).map((option, i) => {
     
      return (
        <>
    <React.Fragment>
        {finalScreen && backScreen ? <div className="flex items-center">
        <button className="flex items-center finalBackBtn mr-3" data-goto={backScreen} onClick={clickHandler}>
        <img data-goto={backScreen} onClick={clickHandler} src={backIcon} alt="Back Icon"/>
        <span data-goto={backScreen} onClick={clickHandler} className="s-decision-tree__title">zurück</span>
       </button>
        <button className="flex items-center finalRestartBtn"  onClick={() => window.location.reload()}>
        <img src={resetIcon} alt="Back Icon"/>
        <span className="s-decision-tree__title">Erneut starten</span>
       </button>

          </div> : backScreen && !finalScreen && backScreen !== "reload" ? <button className="flex items-center backBtn" data-goto={backScreen} onClick={clickHandler}>
        <img data-goto={backScreen} onClick={clickHandler} src={backIcon} alt="Back Icon"/>
        <span data-goto={backScreen} onClick={clickHandler} className="s-decision-tree__title">zurück</span>
       </button> : backScreen  && backScreen === "reload" ? <button className="flex items-center backBtn" data-goto={"start"} onClick={() => window.location.reload()}>
        <img data-goto={"start"} onClick={clickHandler} src={backIcon} alt="Back Icon"/>
        <span data-goto="start" onClick={clickHandler} className="s-decision-tree__title">zurück</span>
       </button> : null
        }
     
      </React.Fragment>
        </>
     
      );
    });
  
   
  }

  return (
    <div className="">
    {backButton()}
      <div className="flex flex-col s-app__container__answer">{
        options && options.length > 0 && options[0].answer.includes("momentum") ?
        MomentumInvestments(): options && options.length > 0 && options[0].answer.includes("literature1") ?
     Literatur(): options && options.length > 0 && options[0].answer.includes("step-no") ?
     NoScreenOption() : options && options.length > 0 && options[0].answer.includes("literature2") ?
     LiteraturScreen2() : options && options.length > 0 && options[0].answer.includes("literature3") ?
     LiteraturScreen3(): options && options.length > 0 && options[0].answer.includes("side-screen-3") ?
     Side3() : options && options.length > 0 && options[0].answer.includes("1") ?
     FinalScreen1() : options && options.length > 0 && options[0].answer.includes("2") ?
     FinalScreen2() : options && options.length > 0 && options[0].answer.includes("3") ?
     FinalScreen3() : options && options.length > 0 && options[0].answer.includes("4") ?
     FinalScreen4() : options && options.length > 0 && options[0].answer.includes("5") ?
     Final5()  : options && options.length > 0 && options[0].answer.includes("6") ?
     Final6() : options && options.length > 0 && options[0].answer.includes("7") ?
     Final7() : options && options.length > 0 && options[0].answer.includes("8") ?
     Final8():<> <div className={`absoluteBtn ${options && options.length === 2 && `md:mt-0 mt-20`} ${options && options.length > 2 && `absoluteMobileBtn`}`}>{optionList()} </div> <Footer questionPage={true}/></>
        
        }</div>
    </div>
  );
};

export default Options;
