import React,{useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import villiamLogo from "../../../images/logo_neu.svg";
import checkIcon from "../../../images/check.png";
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen8 = ({tips, tips2,tips3, tips4}) => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/motiv-nachhaltig');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Robo-Advisor im Allgemeinen sind Algorithmen, die Ihnen, basierend auf Ihrem individuellen Anlageprofil, die beste Portfoliozusammensetzung vorschlagen.
<br />
<br />
Robo-Advisor sind sehr günstig und nutzen wissenschaftliche Erkenntnisse als Grundlage für ihre Vorschläge.
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
      <div className="s-decision-tree__container">
        <img className="newLogo" src={villiamLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Villiam")}>Villiam</a>
            <p className="s-decision-tree__container__subtitle">Ihr  unabhängiger Robo-Advisor</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Villiams Musterportfolios basieren auf der aktuellen Kapitalmarktforschung und ermöglichen Ihnen Zugang zu allen Anlageklassen.</p>
<div className="md:flex items-center justify-between mt-8">
 <div >
<div className="flex items-center">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
    Faktenbasiert
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
Unabhängig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
  Günstig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
 Sicher
      </p>
</div>

</div>

      <div className="md:mt-0 mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Villiam")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>
     
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mb-4">Nachhaltiges Investieren lohnt sich doppelt: Die Erträge sind seit einiger Zeit höher, als bei normalen Investments und es macht zudem Freude und bringt Zufriedenheit, einen Beitrag zu einer nachhaltigeren Welt zu leisten. </p>
          </div>
          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Das Thema Nachhaltigkeit bringt durchaus Herausforderungen mit sich: Wenn bestimmte Anlagen ausgeschlossen werden, wird mein Investment Universum kleiner. 
<br />
<br />
Daraus resultiert ein Anstieg des Risikos, die Möglichkeit zur Diversifikation nimmt ab. Sollten darüber hinaus immer mehr Anleger in die gleichen nachhaltigen Anlagen investieren, steigen diese zwar an, es kommt aber auch zu einer Überbewertung - diese wird wieder abgebaut. 
<br />
<br />
Bei passiven Anlagen, z.B. mit ETFs, fließt kein Geld mehr in Unternehmen, die Kapital zum nachhaltigen Umbau benötigen, aber noch nicht "nachhaltig" sind. Der Umbau der Wirtschaft wird somit nicht unterstützt und finanziert. </p>
       
        
   
          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Der Verbraucherschutz unterstützt grundsätzlich nachhaltige Anlagen, vor allem über ETFs.
        </p>
          {/* <a href="#" className="s-decision-tree__link ml-8   text-base">Mehr informationen</a> */}
          </div>


          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Lesen Sie selbst!
        </p>
           <a href="https://www.finanztip.de/indexfonds-etf/nachhaltige-geldanlagen/" target="_blank" className="s-decision-tree__link  text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/indexfonds-etf/nachhaltige-geldanlagen/")}>Mehr Informationen</a> 
          </div>


          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">Als Eltern tragen wir Verantwortung für unsere Umwelt und wollen diese unseren Kindern so überlassen, dass diese ohne Einschränkungen leben können. Nachhaltigkeit liegt uns am Herzen. 

<br />
<br />
Erstaunlich ist, dass es dafür seit einigen Jahren sogar mehr Rendite gibt. Vorsicht ist bei "Greenwashing" geboten, also einem grünen Anstrich einer nicht nachhaltigen Investition.
Also immer genau hinsehen - das übernehmen wir für Sie!
</p>  




          </div>




      </div>

    <SocialShare />
     
    <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen8