import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import quirinLogo from "../../../images/quirinbank.png";
import quirinFrame from "../../../images/quirinframe.png"
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen3 = () => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/beratung-bank');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
Sie möchten Spaß haben, Zeit mit Ihren Investments verbringen und sich zu Ihren Entscheidungen beraten lassen.
<br />
      <br />
Das können Sie mit einem persönlichen Bankberater am besten umsetzen.
    
   
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
      <div className="s-decision-tree__container">
        <img className="newLogo" src={quirinLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.quirinprivatbank.de/?utm_source=villiam" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Quirin Privatbank AG")}>Quirin Privatbank AG</a>
            <p className="s-decision-tree__container__subtitle">Unabhängige Hornorarberatung</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Die Quirin Privatbank AG ist Deutschlands erste auf Honorarberatung spezialisierte Bank. Die Quirin Privatbank bietet wissenschaftlich fundierte Anlagestrategie und ein transparentes Preismodell.</p><div className="mt-8 md:flex justify-between items-center">
<div className="">
<img className="newFrame" src={quirinFrame}/>

</div> 

      <div className="mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.quirinprivatbank.de/?utm_source=villiam" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Quirin Privatbank AG")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>

      {/* <div className="s-decision-tree__container mt-4">
        <img src={consorsBankLogo}/>

        <div className="mt-4">
            <p className="s-decision-tree__container__title">Consorbank</p>
            <p className="s-decision-tree__container__subtitle">Günstiger Discountbroker</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Die Consorbank hat rund 1,5 Millionen Kunden in Deutschland und gehört zu den führenden Direktbanken Europas.
</p>
<div className="mt-8 md:flex justify-between items-center">
<div >
<img src={consorsBankFrame}/>

</div> 

      <div className="mt-4 flex justify-between items-center">
  
  <button 
         className="s-decision-tree__btn ">
    Zum Anbieter
  </button>
  
  </div>
  </div>
      </div> */}
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mb-4">
        Die Quirin Privatbank AG ist eine Honorarberatungsbank, die Beratung ab 25.000,- Euro deutschlandweit anbietet.  
         </p>
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Bei "Entscheidungen unter Unsicherheit" gibt es die Ausprägung "Risiko", bei der die Eintrittswahrscheinlichkeiten für Ereignisse bekannt sind. 
<br />
<br />
Für einen langfristigen Anlagehorizont trifft das bei Aktienanlagen zu. Bei der Ausprägung "Unsicherheit" sind die Eintrittswahrscheinlichkeiten unbekannt. Das trifft bei einem mittelfristigen Anlagehorizont bei Aktien zu. 
<br />
<br />
Gute Entscheidungen verlangen hier Intuition und kluge Faustregeln (Heuristiken). Ein erfahrener Berater kann unter dieser Prämisse grundsätzlich gute Entscheidungen treffen.
</p>       
   
          </div>


     
          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Lesen Sie selbst!        </p>
         <a href="https://www.finanztip.de/aktien/aktien-kaufen/" target="_blank" className="s-decision-tree__link   text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/aktien/aktien-kaufen/")}>Mehr Informationen</a> 
          </div>

          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Lesen Sie selbst!        </p>
         <a href="https://www.private-banking-magazin.de/das-sind-die-gewinner-des-elite-reports-2021/?page=2" target="_blank" className="s-decision-tree__link   text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.private-banking-magazin.de/das-sind-die-gewinner-des-elite-reports-2021/?page=2")}>Mehr Informationen</a> 
      
          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">
      Persönliche Beratung ist eine Frage des Vertrauens und der passenden "Chemie". 
<br />
<br />
Insoweit gilt es, in einer Bank den richtigen Ansprechpartner zu finden. 
<br />
<br />
Frei nach George Walther: "Sag, was du meinst, und du bekommst, was du willst".
<br />
<br />
</p>  
<a href="https://www.finanztip.de/wertpapierdepot/" target="_blank" className="s-decision-tree__link   text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/wertpapierdepot/")}>Mehr Informationen</a> 

          </div>




      </div>

      <SocialShare />
     
      <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen3