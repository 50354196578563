/* 
This is the content for the Wayfinder questions and answer options.

The user's responses are logged via the options.goto value in Wayfinder.state.usersPath. 

Every options.goto value must be UNIQUE. When ammending the data, please keep this in mind. The wayfinderData.test will throw an error if there are any resulting errors. 

Every unique result ending in a FORM component must have a unique 'complete-form' goto.value (ie 'complete-form2', 'complete-form3' etc.)

Stubs with no further questions are marked as 'leaf' (In the flow chart tree, a leaf is a stub that has no possible further branches.)
*/

const formMsg =
  'If you require further advice and support, please complete the form below and we will contact you within 3 working days.';

const wayfinder = {

  
  start: {
    progressTitle: "DEN RICHTIGEN PARTNER FINDEN",
    progressBar: 30,
    question: '<h1 class=s-decision-tree__question>Ich möchte Geld anlegen über...</h1>',
    options: [
      { answer: 'eine Bank.', goto: 'beim-geld' },
      { answer: 'eine Versicherung.', goto: 'step-no' }
    ]
  },
  "start": {
    progressTitle: "DEN RICHTIGEN PARTNER FINDEN",
    progressBar: 30,
    backScreen: "reload",
    question: '<h1 class=s-decision-tree__question>Ich möchte Geld anlegen über...</h1>',
    options: [
      { answer: 'eine Bank.', goto: 'beim-geld' },
      { answer: 'eine Versicherung.', goto: 'step-no' }
    ]
  },
  "step-no": {
 
    progressTitle: "GELDANLAGE ÜBER EINE VERSICHERUNG",
    progressBar: 40,
    question: '<h1 class=s-decision-tree__question>Nein!</h1>',
    options: [{answer: "step-no", goto: "step-no"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  'beim-geld': {
    backScreen: "start",
    progressTitle: "AKTIVITÄTSGRAD FESTLEGEN",
    progressBar: 40,
    question: '<h1 class=s-decision-tree__question>Beim Geld anlegen möchte ich...</h1>',
    options: [
      { answer: 'Spaß haben.', goto: 'top-3' },
      { answer: 'meine Ruhe haben.', goto: 'bottom-3' }
    ]
  },
  'top-3': {
    backScreen: "beim-geld",
    progressTitle: "ZEITVERPFLICHTUNG WÄHLEN",
    progressBar: 45,
    question: '<h1 class=s-decision-tree__question>Für meine Investments habe ich...</h1>',
    options: [
      { answer: 'viel Zeit.', goto: 'momentum-investements' },
      { answer: 'keine Zeit.', goto: 'bottom-3' }
    ]
  },
  'momentum-investements': {
  
    progressTitle: "MIT SPASS UND ZEIT INVESTIEREN",
    progressBar: 50,
    question: '<h1 class=s-decision-tree__question>Momentum-Investements</h1',
    options: [{answer: "momentum", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  'literature1': {
    
    progressTitle: "MIT SPASS UND ZEIT INVESTIEREN",
    progressBar: 50,
    question: '<h1 class=s-decision-tree__question>Literatur</h1>',
    linkText: `Finanztip.de`,
  linkText2: `Europäische Zentralbank`,
    linkText3: `Handelsblatt`,
    options: [{answer: "literature1", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  'literature2': {
    progressTitle: "GELDANLAGE ÜBER EINE VERSICHERUNG",
    progressBar: 40,
    question: '<h1 class=s-decision-tree__question>Literatur</h1>',
    linkText: `Finanztip.de`,
  linkText2: `Europäische Zentralbank`,
    linkText3: `Handelsblatt`,
    options: [{answer: "literature2", goto: "literature2"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  'literature3': {
    progressTitle: "BERATUNG ERHALTEN",
    progressBar: 60,
    question: 'Literatur',
    linkText: `Finanztip.de`,
  linkText2: `Europäische Zentralbank`,
    linkText3: `Handelsblatt`,
    options: [{answer: "literature3", goto: "literature3"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "bottom-3": {
    backScreen: "beim-geld",
    progressTitle: "IDENTIFIKATION MIT DER GELDANLAGE",
    progressBar: 50,
    question: "<h1 class=s-decision-tree__question>Ich möchte...</h1>",
    options: [
        { answer: 'wissenschaftlich und in das objektiv beste investieren.', goto: 'mein-kapital' },
        { answer: 'nach bestimmten Kriterien und Motiven investieren.', goto: 'nach-bestimmten' }
      ]
  },
  "mein-kapital": {
    backScreen: "bottom-3",
    progressTitle: "ANLAGEHORIZONT",
    progressBar: 80,
    question: "<h1 class=s-decision-tree__question>Mein Kapital...</h1>",
    options: [
        { answer: 'soll stets verfügbar (liquide) sein.', goto: 'top-final-form-6' },
        { answer: 'kann langfristig investiert (illiquide) sein.', goto: 'top-final-form-5' }
      ]
  },
  "step-4": {
    backScreen: "momentum-investements",
    progressTitle: "SELBSTBESTIMMUNGSGRAD  FESTLEGEN",
    progressBar: 60,
    question: "<h1 class=s-decision-tree__question>Investment<wbr>&shyentscheidungen möchte ich...</h1>",
    options: [
      { answer: 'selbstständig treffen.', goto: 'top-final-form-1' },
      { answer: 'unter Anregungen fällen.', goto: 'top-final-form-2' },
      { answer: 'im Austausch mit einem Berater treffen.', goto: 'side-screen-3' }, 
    ]
  },
  "side-screen-3":  {
    progressTitle: "BERATUNG ERHALTEN",
    progressBar: 60,
    question: "<h1 class=s-decision-tree__question>Gute Beratung lohnt sich!</h1>",
    options: [
      { answer: 'side-screen-3', goto: 'side-screen-3' },
    ]
  },
  "step-5": {
    backScreen: "side-screen-3",
    progressTitle: "BERATUNGSPARTNER WÄHLEN",
    progressBar: 80,
    question: "<h1 class=s-decision-tree__question>Ich möchte mich beraten lassen bei...</h1>",
    options: [
      { answer: 'einer Bank.', goto: 'top-final-form-3' },
      { answer: 'einem Vermögensverwalter.', goto: 'top-final-form-4' }, 
    ]
  },
  "top-final-form-8": {
    backScreen: "nach-bestimmten",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Ein Robo-Advisor mit nachhaltigen Anlagen.</h1>",
    options: [{answer: "top-final-form-8", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-7": {
    backScreen: "nach-bestimmten",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Ein Discountbroker mit Motiv-Investments.</h1>",
    options: [{answer: "top-final-form-7", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-5": {
    backScreen: "mein-kapital",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Ein Robo-Advisor mit liquiden und illiquiden Anlagen.</h1>",
    options: [{answer: "top-final-form-5", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-6": {
    backScreen: "mein-kapital",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Ein Robo-Advisor mit liquiden Anlagen.</h1>",
    options: [{answer: "top-final-form-6", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-4": {
    backScreen: "step-5",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Mit einem unabhängigen Vermögensverwalter zum Erfolg</h1>",
    options: [{answer: "top-final-form-4", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-3": {
    backScreen: "step-5",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Starten Sie mit einem persönlichen Bankberater.</h1>",
    options: [{answer: "top-final-form-3", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]
  },
  "top-final-form-2": {
    backScreen: "step-4",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Nehmen Sie sich einen Discountbroker</h1>",
    options: [{answer: "top-final-form-2", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]

  },

  "top-final-form-1": {
    backScreen: "step-4",
    finalScreen: true,
    progressTitle: "UNSER TIPP FÜR SIE",
    progressBar: 100,
    question: "<h1 class=s-decision-tree__question>Nehmen Sie sich einen Neobroker</h1>",
    options: [{answer: "top-final-form-1", goto: "literature1"}, {answer: "back", goto: "top-3"}, {answer: "next", goto: "top-4"}]

  },
  "viel-zeit": {
    progressTitle: "IDENTIFIKATION MIT DER GELDANLAGE",
    progressBar: 50,
    question: "<h1 class=s-decision-tree__question>Ich möchte...</h1>",
    options: [
        { answer: 'wissenschaftlich und in das objektiv beste investieren.', goto: 'nach-bestimmten' },
        { answer: 'nach bestimmten Kriterien und Motiven investieren.', goto: 'nach-bestimmten' }
      ]
  },
  'wissenschaftlich': {
    question:
      '<h1 class=s-decision-tree__question>Are you familiar with Certificates of deposit?</h1>',
     
    options: [
      { answer: 'no', goto: 'need-advice' },
      { answer: 'yes', goto: 'need-advice' }
    ]
  },
  'nach-bestimmten': {
    backScreen: "bottom-3",
    progressTitle: "ANLAGEHORIZONT",
    progressBar: 70,
    question:
      '<h1 class=s-decision-tree__question>Meine Geldanlagen sollen vor allem...</h1>',
      options: [
        { answer: 'nachhaltigen Kriterien entsprechen.', goto: 'top-final-form-8' },
        { answer: 'anderen Motiven folgen (z.B. Digitalisierung, Weltraum, e-Mobilität).', goto: 'top-final-form-7' }
      ]  },
  



  'complete-form': {
    result: `${formMsg}`,
    leaf: true
  },
  'complete-form-1': {
    result: `${formMsg}`,
    leaf: true
  },
  'complete-form-2': {
    result: `${formMsg}`,
    leaf: true
  },
  'complete-form-3': {
    result: `${formMsg}`,
    leaf: true
  },
  'complete-form-4': {
    result: `${formMsg}`,
    leaf: true
  },
  "complete-form-no": {
      result: "literatur",
      leaf: true
  }
};

export default wayfinder;