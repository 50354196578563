import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import villiamLogo from "../../../images/logo_neu.svg";
import checkIcon from "../../../images/check.png";
import consorsBankFrame from "../../../images/consorbank-frame.png"
import consorsBankLogo from "../../../images/consorbank-logo.png";
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen5 = ({paragraph, frame, secondTitle, secondSubtitle, secondBankLogo, paragraph3, title, isMulti, description, subtitle, paragraph2, logo, tips, tips4, tips2, tips3}) => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/robo-illiquidite-assets');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Robo-Advisor im Allgemeinen sind Algorithmen, die Ihnen, basierend auf Ihrem individuellen Anlageprofil, die beste Portfoliozusammensetzung vorschlagen.

      <br />
      <br />
      Robo-Advisor sind sehr günstig und nutzen wissenschaftliche Erkenntnisse als Grundlage für ihre Vorschläge.
   
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
      <div className="s-decision-tree__container">
        <img className="newLogo" src={villiamLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Villiam")}>Villiam</a>
            <p className="s-decision-tree__container__subtitle">Ihr unabhängiger Robo-Advisor</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Villiams Musterportfolios basieren auf der aktuellen Kapitalmarktforschung und ermöglichen Ihnen Zugang zu allen Anlageklassen.</p>
<div className="md:flex items-center justify-between mt-8">
 <div className="">
<div className="flex items-center">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
    Faktenbasiert
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
Unabhängig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
  Günstig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
 Sicher
      </p>
</div>

</div>

      <div className="md:mt-0 mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Villiam")}>
    Zum Anbieter
  </a>
  </div>
  </div>
      </div>

      {/* <div className="s-decision-tree__container mt-4">
        <img src={consorsBankLogo}/>

        <div className="mt-4">
            <p className="s-decision-tree__container__title">Consorbank</p>
            <p className="s-decision-tree__container__subtitle">Günstiger Discountbroker</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Die Consorbank hat rund 1,5 Millionen Kunden in Deutschland und gehört zu den führenden Direktbanken Europas.
</p>
<div className="mt-8 md:flex justify-between items-center">
<div >
<img src={consorsBankFrame}/>

</div> 

      <div className="mt-4 flex justify-between items-center">
  
  <button 
         className="s-decision-tree__btn ">
    Zum Anbieter
  </button>
  
  </div>
  </div>
      </div> */}
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mb-4">
        "Illiquidität" wird am Kapitalmarkt mit einer Prämie vergütet, also mit einem Zusatzertrag gegenüber liquiden Anlagen. 
<br />
<br />
Alle Vermögensteile, die langfristig zur Verfügung stehen, können sich diese Prämie nutzbar machen - Langfristigkeit lohnt sich!
<br />
<br />
Die amerikanischen Universitäten setzen das seit jahrzehnten erfolgreich um. Der Chef der Allianz Versicherung, Oliver Bäte sagt: "Lediglich da, wo Investments weniger liquide sind,..., erreichen wir Vorteile auf der Renditeseite."
        
         </p>
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Ein Vermögen, das illiquide Vermögenswerte beinhaltet, weist ein deutlich besseres Risiko-Ertrags-Profil auf. 

<br />
<br />
Ein Mehr an "Rendite" kann eben nicht nur durch ein Mehr an "Risiko" erkauft werden, sondern auch über ein Mehr an “Illiquidität” (eingeschränkte Verfügbarkeit). 

<br />
<br />
Langfristigkeit lohnt sich! Liquidität ist teuer!

</p>       
<a href="https://www.finanztip.de/indexfonds-etf/nachhaltige-geldanlagen/" className="s-decision-tree__link   text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/indexfonds-etf/nachhaltige-geldanlagen/")}>Mehr Informationen</a>
   
          </div>


     
          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Nachdem Banken illiquide Anlagen zwar selber tätigen, aber den Kunden nicht im Rahmen einer Vermögensverwaltung anbieten, gibt es auch kein Robo-Advisor Angebot am Markt und damit keine Aussagen vom Verbraucherschutz dazu.
        </p>
          {/* <a href="#" className="s-decision-tree__link ml-8   text-base">Mehr informationen</a> */}
          </div>

          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Nachdem in Deutschland grundsätzlich keine gemischten - liquiden und illiquiden - Vermögensverwaltungs-Mandate angeboten werden, gibt es auch keine Bewertungen.
        </p>
      
          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">Villiam bringt die Kapitalanlagen der Profis zu allen Anlegern am Markt. Wir demokratisieren die Illiquiditäts-Prämie und empowern die Kunden - die jetzt wie ein Profi anlegen können und von den Früchten der Illiquidität und Langfristigkeit profitieren.
</p>  
          </div>




      </div>

     <SocialShare />
     <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen5