import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import bauerBank from "../../../images/bauerbank.png";
import djeBankLogo from "../../../images/djebank-logo.png";
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen4 = () => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/beratung-vermoegensberater');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Bei diesem Modell benötigen Sie eine Bank und einen Vermögensberater.
        </p>
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
     

      <div className="s-decision-tree__container">
        <img className="newLogo" src={bauerBank}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="http://www.drbauer-co.de/?ucf=cmk-consulting-gmbh" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Dr. Bauer & Co. Vermögensverwaltung")}>Dr. Bauer & Co. Vermögensverwaltung</a>
            <p className="s-decision-tree__container__subtitle">Finanzberater</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Dr. Bauer & Co. bietet 20 Jahre Erfahrung in der Finanzberatung und hat Niederlassungen in München und Kitzbühel.
</p>
<div className="mt-8 md:flex justify-between items-center">


      <div className="mt-4 flex justify-right items-center md:ml-auto">
  
  <a 
         className="s-decision-tree__btn md:ml-auto md:p-0 p-4 btnLink" href="http://www.drbauer-co.de/?ucf=cmk-consulting-gmbh" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Dr. Bauer & Co. Vermögensverwaltung")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>

      <div className="s-decision-tree__container mt-4">
        <img className="newLogo" src={djeBankLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.dje.de/vermogensverwaltung/uberblick/?utm_source=villiam" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "DJE Kapital AG")}>DJE Kapital AG</a>
            <p className="s-decision-tree__container__subtitle">Vermögensverwaltung</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Ausgehend von Ihren Vorgaben und Wünschen entwickelt die DJE Kapital AG eine für Sie passende Anlagestrategie.
</p>


      <div className="mt-4 flex justify-between md:ml-auto items-center">
  
  <a 
         className="s-decision-tree__btn md:ml-auto md:p-0 p-4 btnLink"  href="https://www.dje.de/vermogensverwaltung/uberblick/?utm_source=villiam" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "DJE Kapital AG")}>
    Zum Anbieter
  </a>
  
  </div>
      </div>
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mb-4">
        Bei einem Vermögensverwalter gibt es zwei Partner: den Verwalter selbst und die Bank, die das Depot führt.
<br />
<br />
Die Verwalter sind meist regional angesiedelt und geben Mindestanlagesummen für eine Zusammenarbeit vor. Vorteil ist, dass die meisten unabhängig sind, und so für die Kunden die besten Lösungen anbieten können. 
<br />
<br />
Sie müssen ein gutes Gefühl haben, dass es für sie passt!
         </p>
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Karl Valentin: "Prognosen sind schwierig, besonders wenn sie die Zukunft betreffen". Das ist wohl auch für den Aktienmarkt zutreffend.
<br />
<br />
In der Risikoforschung gibt es bei "Entscheidungen unter Unsicherheit" die Ausprägung "Risiko" - hier sind Eintritts-Wahrscheinlichkeiten bekannt - und "Ungewissheit" - hier ist nicht alles bekannt und die beste Option lässt sich nicht berechnen. 
<br />
<br />
Da der Momentum-Effekt wissenschaftlich nachgewiesen ist, gibt es durch logisches und statistisches Denken  auch gute Entscheidungen, die ertragswirksam umgesetzt werden können.
</p>       
   
          </div>


          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-4">

        Grundsätzlicher Vorteil ist die Unabhängigkeit der Verwalter. 

<br />
<br />
In Frage gestellt wird, ob der aktive Managementansatz einen Vorteil für den Kunden bringt. 

<br />
<br />
Als objektiven Erfolgsmaßstab sollten Sie immer einen zu Ihrer Vermögensaufteilung passenden <a className="s-decision-tree__link text-xs" href="https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true" target="_blank" style={{fontSize: "16px", textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true")}>ETF </a> im Depot mitführen. (Benchmark)

<br />
<br />
ETFs legen grundsätzlich passiv an und sind zudem sehr günstig. Grundsatz: Wenn der Verwalter nicht besser ist, ist er sein Geld nicht wert.
</p>         
          </div>

       
          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Lesen Sie selbst!
</p>
<a href="https://www.private-banking-magazin.de/das-sind-die-gewinner-des-elite-reports-2021/?page=2" target="_blank" className="s-decision-tree__link   text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.private-banking-magazin.de/das-sind-die-gewinner-des-elite-reports-2021/?page=2")}>Mehr Informationen</a> 

          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">

Wir schließen uns dem Verbraucherschutz an: 
<br />
<br />
Legen Sie sich einen passenden <a className="s-decision-tree__link text-xs" href="https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true" target="_blank" style={{fontSize: "16px", textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true")}>ETF </a> zusätzlich ins Depot, dann haben Sie einen objektiven Erfolgsmaßstab!
</p>         

 </div>




      </div>

      <SocialShare />
     
      <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen4