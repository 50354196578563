import logo from "../images/logo_neu.svg"



const Header = () => {
    return (
        <header class="flex flex-wrap place-items-center">
        <section class="relative mx-auto md:w-full">   
          <nav class="flex justify-between bg-white text-white md:w-full w-screen">
            <div class="py-6 flex items-center">
              <a class="text-3xl font-bold font-heading" href="/">
                <img class="h-9" src={logo} alt="logo" />
              </a>
              </div>
             </nav>
            </section>
           </header>
    )
}

export default Header;