import React, {useState} from "react";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinShareButton,
    WhatsappIcon,
  } from "react-share";
  import instagram from "../../images/instagram.png"
  import shareIcon from "../../images/share-icon.png";
  import {CopyToClipboard} from 'react-copy-to-clipboard';
import linkedInLogo from "../../images/linkedin-icon.svg"
import twitterLogo from "../../images/twitter-icon.svg"
import facebookLogo from "../../images/facebook-icon.svg"
import whatsappLogo from "../../images/whatsapp-icon.svg"
import ReactGA from 'react-ga';

const SocialShare = () => {
    const [copied, setCopied] = useState(false)
    const shareUrl = "https://invest-o-mat.villiam.eu"

      // function to track click events of links and buttons in google analytics
      const eventTrack = (category, action, label) => {
        console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
          category: category,
          action: action,
          label: label,
        })
      }

    return (
        <section className="mt-10 mb-20">
        <h3 className="s-decision-tree__link--dark">Hat Ihnen der Invest-O-Mat gefallen?</h3>
          
          <div className="s-decision-tree__container mt-2 ">
          <div className="flex items-center mb-4">
              <img src={shareIcon} alt="Share Icon" style={{maxWidth: "30px"}} />
              <p className="ml-2 s-decision-tree__link--dark">Jetzt teilen und Freunden und Familie davon erzählen!</p>
          </div>
<div className="md:flex justify-between items-center">

          <CopyToClipboard  text="https://invest-o-mat.villiam.eu"
          onCopy={() => setCopied(true)}>
          <button 
       className={`s-decision-tree__btn--final  md:mb-0 mb-4 ${copied && `copied`}`} onClick={() => eventTrack( "Share", "Click", "Copy Link")}>
    {`${copied ? `In Zwischenablage kopiert!` : `Link kopieren`}`}
</button>

</CopyToClipboard>

<div className="md:mt-0 mt-2 flex items-center">
<TwitterShareButton
      title={"Share"}

      
      className="mr-8"
    url={shareUrl}
  >
    <img onClick={() => eventTrack( "Social Share", "Social-Icon", "Twitter")} src={twitterLogo} alt="Twitter Logo"/>
  </TwitterShareButton>
           
        
            <FacebookShareButton
      title={"Share"}
      
      className="mr-8"
      url={shareUrl}
  >
   <img  onClick={() => eventTrack( "Social Share", "Social-Icon", "Facebook")} src={facebookLogo}  alt="FacebookLogo"/>
  </FacebookShareButton>
          
       <LinkedinShareButton  title={"Share"}
      media={linkedInLogo}
      className="mr-8"
      url={shareUrl}>
<img onClick={() => eventTrack( "Social Share", "Social-Icon", "LinkedIn")} src={linkedInLogo} alt="LinkedInLogo"/>
       </LinkedinShareButton>
            {/* <img  className="mr-8" src={instagram} alt="Instagram Logo"/> */}
        
             
            <WhatsappShareButton
      title={"Share"}
      
      className=""
      url={shareUrl}
  >
   <img onClick={() => eventTrack( "Social Share", "Social-Icon", "WhatsApp")} src={whatsappLogo} style={{maxWidth: "30px"}} alt="Whatsapp logo"/>
  </WhatsappShareButton>
  </div>
</div>

          </div>
   
    </section>
    )
}

export default SocialShare