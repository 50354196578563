import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import comdirectLogo from "../../../images/comdirectbank-logo.png";
import comdirectFrame from "../../../images/comdirectbank-frame.png"
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen7 = ({tips, tips2,tips3, tips4}) => {

  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/motiv-andere');


    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Sie möchten nach Ihren eigenen Motiven investieren. Dazu ist es hilfreich, wenn Sie eine Bank nutzen, die die Geldanlagen für Sie auf Ihre Kriterien prüft und danach aussucht.
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
      <div className="s-decision-tree__container">
        <img className="newLogo" src={comdirectLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.financeads.net/tc.php?t=46604C87024090T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "comdirect")}>comdirect</a>
            <p className="s-decision-tree__container__subtitle">Direktbank der Commerzbank</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Die comdirect bietet viele kostenlose Sparpläne und einen großen Katalog an Motiv-Investments.
</p>
<div className="mt-8 md:flex justify-between items-center">
 <div >
<img className="newFrame" src={comdirectFrame}/>

</div>

      <div className="mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.financeads.net/tc.php?t=46604C87024090T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "comdirect")}>
    Zum Anbieter
  </a>
  
  </div>
      </div>
      </div> 
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
       <p className="s-decision-tree__paragraph mb-4">Motiv-Investments machen Spaß, weil man sich mit der jeweiligen Anlage identifizieren kann und in das investiert, an das man glaubt.</p> 
       <p className="s-decision-tree__paragraph mb-4">Das kann helfen, auch in schwierigen Kapitalmarktphasen durchzuhalten - denn Langfristigkeit lohnt sich!</p>

      
      <p className="s-decision-tree__paragraph ">Wichtig ist: </p>


        <ul className=" list-disc w-11/12 ml-8 mx-auto">
        <li className="s-decision-tree__paragraph">dass Sie nicht jedem Trend nachlaufen, sondern eine gewisse strategische Ruhe bewahren</li>
        <li className="s-decision-tree__paragraph">dass Sie eine genügend breite Streuung nach Themen, Branchen, Ländern und Regionen haben</li>
        <li className="s-decision-tree__paragraph">dass Sie auf die Kosten der Produkte achten; schlanke passive Konzepte mit ETF sind zu bevorzugen</li>
        <li className="s-decision-tree__paragraph">dass Sie einen weltweiten <a className="s-decision-tree__link text-xs" href="https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true" target="_blank" style={{fontSize: "16px", textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true")}>ETF </a>als Erfolgsmaßstab mitführen, um Ihren Erfolg objektiv zu messen</li>
       
          </ul>
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">Die Risikoforschung ist von einer Konzentration auf wenige Themen und Branchen nicht begeistert, sie nennt das "home bias". Das kostet Rendite und erhöht das Risiko - Spielverderber!</p>
       
        
   
          </div>


          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
      <p className="s-decision-tree__paragraph mt-4 ">Der Verbraucherschutz warnt zu Recht davor, dass die Bankenbranche ständig neue Themen anpreist und damit Kunden zum Kauf von teuren Produkten animiert. 

</p>  



          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>

          <p className="s-decision-tree__paragraph mt-4">Über Motiv-Investments gibt es wenig Informationen und Bewertungen.</p>
       
        
   
          </div>

          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">Motiv-Investments können eine Möglichkeit sein, sich mit seinen Kapitalanlagen zu identifizieren und damit langfristig investiert zu bleiben - das begrüßen wir, denn Langfristigkeit lohnt sich! 
<br />
<br />
Wir empfehlen im Aktienteil nur 50% in Motiv-Investments anzulegen und den Rest in weltweite <a className="s-decision-tree__link text-xs" href="https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true" target="_blank" style={{fontSize: "16px", textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true")}>ETFs</a>. Das bedeutet Identifikation und Profi-Erkenntnisse umzusetzen und das bitte langfristig.

</p>  



          </div>

       




      </div>

     <SocialShare />
     
     <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen7