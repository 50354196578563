

import React, {useState} from "react"
import shareIcon from "../images/share-icon.png";
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import {
 
    FacebookShareButton,
   
    TwitterShareButton,
 
    WhatsappShareButton,
    LinkedinShareButton,
    FacebookIcon,
    WhatsappIcon,
   
  } from "react-share";
  import instagram from "../images/instagram.png"
  import twitterLogo from "../images/twitter-icon.svg"
  import facebookLogo from "../images/facebook-icon.svg"
  import linkedInLogo from "../images/linkedin-icon.svg"
import whatsappLogo from "../images/whatsapp-icon.svg"
  import closeIcon from "../images/close.png";
  import {CopyToClipboard} from 'react-copy-to-clipboard';
  import ReactGA from 'react-ga';
Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: "40px",
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const Footer = () => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [value, setValue] = useState("")
    const [copied, setCopied] = useState(false)

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const shareUrl = "https://invest-o-mat.villiam.eu";

  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

    return (
        <footer className="bg-white footer-home  md:mt-0 mt-10 py-6 flex mx-auto" >
        <div className=" flex justify-between items-center custom-width">      
        <div className="flex items-center text-xs">
                <a href="https://www.villiam.eu/impressum" target="_blank">
                Impressum |
                </a>
                <a className="s-footer__title ml-1" target="_blank" href="https://www.villiam.eu/data-privacy">
            Datenschutz
            </a>
            </div>
  
  
<div className="s-footer__shareBtn--desktop cursor-pointer md:block hidden" onClick={openModal}>
<img src={shareIcon} alt="share-icon"/>
</div>
<div className="s-footer__shareBtn--mobile cursor-pointer block md:hidden" onClick={openModal}>
<img src={shareIcon} alt="share-icon"/>
</div>
        </div>

<Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  style={customStyles}
>
<div className="p-4 relative">
<h3 className="s-main__title mb-4 ">Gefällt dir der Invest-O-Mat?</h3>
<div className="s-decision-tree__container mt-2 ">
            <div className="flex items-center mb-4">
                <img src={shareIcon} alt="Share Icon" style={{maxWidth: "30px"}} />
                <p className="ml-2 s-decision-tree__link--dark">Jetzt teilen und Freunden und Familie davon erzählen!</p>
            </div>

          


<div className="md:flex items-center justify-between">
  <CopyToClipboard  text="https://invest-o-mat.villiam.eu"
          onCopy={() => setCopied(true)}>
            <button 
             className={`s-footer__clipboard md:m-2 m-0  ${copied && `copied`}`}
        >
    {`${copied ? `In Zwischenablage kopiert!` : `Link kopieren`}`}
  </button>
        </CopyToClipboard>
  <div className="mt-2 md:m-2 m-0 flex items-center">


  <TwitterShareButton
      title={"Share"}

      className="mr-8"
      url={shareUrl}
  >
  <img  onClick={() => eventTrack("Footer Home", "Social-Icon", "Twitter")} src={twitterLogo} alt="Twitter Logo"/>
  </TwitterShareButton>
           
        
            <FacebookShareButton
      title={"Share"}
  
      className="mr-8"
      url={shareUrl}
  >
      <img   onClick={() => eventTrack("Footer Home", "Social-Icon", "Facebook")} src={facebookLogo} alt="Facebook Logo"/>
  </FacebookShareButton>
          
       
  <LinkedinShareButton  title={"Share"}
  
      className="mr-8"
      url={shareUrl}>
<img   onClick={() => eventTrack("Footer Home", "Social-Icon", "LinkedIn")} src={linkedInLogo} alt="LinkedInLogo"/>
       </LinkedinShareButton>
            {/* <img  className="mr-8" src={instagram} alt="Instagram Logo"/> */}
        
             
            <WhatsappShareButton
      title={"Share"}
      
      className=""
      url={shareUrl}
  >
   <img  onClick={() => eventTrack("Footer Home", "Social-Icon", "WhatsApp")} src={whatsappLogo} style={{maxWidth: "30px"}} alt="Whatsapp logo"/>
  </WhatsappShareButton>
     
          

  </div>
  </div>
            </div>
            <div className="absolute top-0 right-0 ml-12 mb-10 closeIcon" onClick={closeModal}>
        <img src={closeIcon} alt="Close Icon" />
            </div>
                    </div>
</Modal>
        </footer>
    )
}

export default Footer;