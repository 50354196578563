import React from 'react';





const Question = ({ question, link, linkText, selectedPath, options }) => {
 
  return (
    <div>
      <div>
      <div dangerouslySetInnerHTML={{__html: question}}></div>
        {/* <p className="s-decision-tree__question" data-testid="question">
          {question}
     
        </p> */}
      </div>
     
    </div>
  );
};

export default Question;
