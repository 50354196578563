
import './styles/index.scss';
import Header from './components/Header';
import Banner from './components/Banner';
import Footer from './components/Footer';
import { useState } from 'react';
import DecisionTree from './components/decision-tree';
import shareIcon from "./images/share-icon.png";
import bgImage from "./images/mountains.png";
import FooterHome from './components/FooterHome';
import ReactGA from 'react-ga';

function App() {
  const TRACKING_ID = "UA-206700438-2";
  ReactGA.initialize(TRACKING_ID);

// function to track click events of links and buttons in google analytics test

  const [start, setStart] = useState(false)

  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
    }

    const onAppStart = () => {
            eventTrack("Start Screen", "Start-Button", "Click");
            setStart(true);  
    }

  return (
      <section className="s-app" style={{backgroundImage: `url(${bgImage})`}}>
      <div className={`s-app__container md:relative ${!start && `s-app__height`}`}>
          <Header />
    <main className="s-main">

    <Banner />

{start ? 
<div className="">
<DecisionTree />
</div>
 : 
  <section className="">
    <div className="custom-width  py-6 flex flex-col items-center md:bg-white s-app__container__start">
<div className="mb-20">
    <h1 className="s-main__title w-full">
    Der Invest-O-Mat
    </h1>

<p className="s-main__paragraph text-xl">
Erfahren Sie in wenigen Schritten, welche Herangehensweise bei der Geldanlage zu Ihnen passt.
</p>
<p className="s-main__paragraph text-xl">
Wir zeigen Ihnen außerdem konkrete Anlagemöglichkeiten, die von Profis für Sie zusammengestellt wurden.
</p>
</div>
<button style={{position: "absolute", bottom: "0" , marginTop: "0px", marginBottom: "110px"}} className="s-main__btn md:left-0 md:ml-20 flex  items-center justify-center md:w-1/2 w-11/12 mx-auto py-2" onClick={() => onAppStart()}>
Start
</button>
    </div>
   <FooterHome />
    </section>

}
  


  
    </main>

    </div>
    </section>

  );
}

export default App;
