import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import villiamLogo from "../../../images/logo_neu.svg";
import checkIcon from "../../../images/check.png";
import quirionFrame from "../../../images/quirionbank-frame.png"
import quirionLogo from "../../../images/quirionbank-logo.png";
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen6 = ({  tips, tips4, tips2, tips3}) => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/robo-liquide-assets');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Robo-Advisor im Allgemeinen sind Algorithmen, die Ihnen, basierend auf Ihrem individuellen Anlageprofil, die beste Portfoliozusammensetzung vorschlagen.

    <br />
      <br />
      Robo-Advisor sind sehr günstig und nutzen wissenschaftliche Erkenntnisse als Grundlage für ihre Vorschläge.  
   
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
     

      <div className="s-decision-tree__container">
        <img className="newLogo" className="newLogo"  src={quirionLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.financeads.net/tc.php?t=46682C65520725T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "quirion")}>quirion</a>
            <p className="s-decision-tree__container__subtitle">Günstiger Robo-Advisor</p>
        </div>

<p className="s-decision-tree__container__paragraph">
quirion ist Testsieger bei Stiftung Warentest und kann mit einem Anlagevolumen ab 500€ verwendet werden.</p>
<div className="mt-8 md:flex justify-between items-center">
<div className="">
<img className="newFrame" src={quirionFrame}/>

</div> 

      <div className="mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.financeads.net/tc.php?t=46682C65520725T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "quirion")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>

      <div className="s-decision-tree__container mt-4">
        <img className="newLogo" className="newLogo" src={villiamLogo}/>

        <div className="mt-4">
        <a className="s-decision-tree__container__title" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Villiam")}>Villiam</a>

            <p className="s-decision-tree__container__subtitle">Ihr  unabhängiger Robo-Advisor</p>
        </div>

<p className="s-decision-tree__container__paragraph">
Villiams Musterportfolios basieren auf der aktuellen Kapitalmarktforschung und ermöglichen Ihnen Zugang zu allen Anlageklassen.</p>
<div className="md:flex items-center justify-between mt-8">
 <div className="">
<div className="flex items-center">
<img className="newLogo" src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
    Faktenbasiert
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
Unabhängig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
  Günstig
      </p>
</div>
<div className="flex items-center mt-2">
<img src={checkIcon} alt="Icon" className="mr-4"/>
<p 
           className="s-decision-tree__link--dark mt-0">
 Sicher
      </p>
</div>

</div>

      <div className="mt-4 flex justify-between items-center">
  
  <a 
         className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.villiam.eu/?utm_source=invest-o-mat" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Villiam")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>
      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mb-4">
        Beide Anbieter haben einen ähnlichen -wissenschaftlich fundierten - Ansatz. 
Bei quirion gibt es nur zwei Anlageklassen: Aktien und Renten.
Bei Villiam kommen mehrere Anlageklassen zum Einsatz. 
<br />
<br />
Bei der Anlageklasse "Aktien" ist Villiam kostenlos - es werden keine Gebühren berechnet. Kauft ein Anleger nur Aktien, ist die komplette Dienstleistung von Villiam kostenlos.<br />
    
         </p>
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Eine gute Aufteilung des Vermögens auf Aktien und Renten kann das Risiko des Anlegers senken. Werden weitere Anlageklassen hinzugefügt, kann das Risiko-Ertrags-Verhältnis noch einmal deutlich optimiert werden.
</p>       
   
          </div>


          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Wissenschaftlich fundierte Ansätze werden vom Verbraucherschutz grundsätzlich positiv gesehen. Im Aktienbereich werden rein passive Ansätze bevorzugt.         </p>
         
          </div>

       
          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Nachdem das Angebot von vielen Assetklassen in Verbindung mit professionellen Produkten in einem Robo Advisor eine Innovation darstellt, gibt es noch keine unabhängige Bewertung.        </p>
      
          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-4">
      Wir bringen die Kapitalanlagen der Profis zu allen Anlegern. Es gibt gute Gründe, warum Profis viele Anlageklassen für sich nutzen. Der Chef der Allianz, Oliver Bäte,  sagt: "Lediglich da, wo Investments weniger liquide sind,...,erreichen wir Vorteile auf der Renditeseite."</p>  
          </div>




      </div>

     <SocialShare />
     
     <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen6