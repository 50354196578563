import React from "react";
import Footer from "../../Footer";
import FadeIn from "react-fade-in";


const Literatur3 = ({clickHandler}) => {
    return (
     
        <div className="mt-4">
 <p className="s-decision-tree__paragraph">
 Hier finden Sie weiterführende Informationen zu dem Thema:
 
   </p>

<div className="flex  flex-col justify-left text-left">
<a href="https://www.amazon.de/gp/product/3570554422/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=villiam05-21&creative=6742&linkCode=as2&creativeASIN=3570554422&linkId=cac3c1a7c2ee3e674014791e75089307" target="_blank" className="s-decision-tree__link mt-4 text-decoration" style={{textDecoration: "underline"}}>
Risiko: Wie man die richtigen Entscheidungen trifft
</a>
{/* <a href="#" className="s-decision-tree__link mt-3">
Europäische Zentralbank
</a>
<a href="#" className="s-decision-tree__link mt-3">
Finanztip.de
</a> */}
</div>

<div className="mt-20 flex justify-between items-center absoluteDoubleBtn">
<button className="s-decision-tree__btn--back" onClick={clickHandler}
      data-goto="side-screen-3">
zurück
</button>


</div>

        </div>
        
    )
}

export default Literatur3