import React from 'react';




const Result = ({ result, link, linkText }) => {
  return (
    <div>
      <p data-testid="result">
        {result}
        {link ? (
          <a
            href={link}
            target="_blank"
            onClick={() => alert('This link will now open in a new tab')}
          >
            {linkText}
          </a>
        ) : null}
      </p>
    </div>
  );
};

export default Result;
