import React, {useState, useEffect} from "react";
import QAContainer from "./QAContainer";
import Thanks from "./Thanks";
import Form from "./Form";
import findKey from "../../utils/findKey";
import data from "../decision-tree/data";
import backIcon from "../../images/back.png"
import resetIcon from "../../images/restart.png"
import Footer from "../Footer";

const DecisionTree = () => {

    const [usersPath, setUsersPath] = useState(['start'])
    const [selectedPath, setSelectedPath] = useState([])
    const [content, setContent] = useState()
    const [showResetBtn, setResetBtn] = useState(false)
    const [hidePrevious, setHidePrevious] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [previous, setPrevious] = useState([])
    useEffect(() => {
        setContent(data)
        setLoading(false)
    },[])

    // const [backScreen, setBackScreen] = useState("")

    // useEffect(() => {
    //   if(previous && previous.length > 0){
    //   let lastItem = []
    //   let slicedPrevious = ""
    //   const tempArr = previous
    //     // const  uniq = [...new Set(previous)];
        
       

    //     if(previous && previous.length === 1){
    //       slicedPrevious = "start"
    //       lastItem = ['start']
          
    //     } else {
    //       slicedPrevious = tempArr.slice(-2,-1)[0]
    //        lastItem = tempArr.slice(-1).pop()
          
    //     }

    //     if(lastItem.includes("final")){
    //       setResetBtn(true)
    //     } else if(lastItem.includes("literatur")){
    //       setHidePrevious(true)
    //     } else if(lastItem.includes("step-no")){
    //       setHidePrevious(true)
    //     } else if(lastItem.includes("momentum")){
    //       setHidePrevious(true)
    //     } else if(lastItem.includes("side-screen-3")){
    //       setHidePrevious(true)
    //     } else {
    //       setHidePrevious(false)
    //       setBackScreen(slicedPrevious)
    //     }
      
    //   } else {
    //     setHidePrevious(true)
    //   }
    // },[previous])
   

   const clickHandler = event => {
 
      setPrevious([...previous, event.target.dataset.goto])

        const goto = event.target.dataset.goto;
        const key = findKey(usersPath, goto);
        const dataKey = event.target.dataset.key;
        let modifiedPath = [...usersPath];
        let modifiedSelected = [...selectedPath];
        modifiedPath.splice(usersPath.indexOf(key) + 1);
        modifiedSelected.splice(usersPath.indexOf(key));
        setUsersPath(modifiedPath.concat(goto))
        setSelectedPath(modifiedSelected.concat(dataKey))
      };

      const   warnUser = e => {
        const confirmationMessage = 'Refreshing will lose your data, continue?';
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      };

    

     const questionList = () => {
      
        return (
          <React.Fragment>
            {!loading && usersPath.map((item, i) => {
              return (
                  usersPath.length === i + 1 && (
                    <QAContainer
                    qablock={content[item]}
                    clickHandler={clickHandler}
                    key={item}
                    setUsersPath={setUsersPath}
                    index={i}
                    totalNumber={usersPath.length}
                    selectedPath={selectedPath[i]}
                  />
                  )
                
              
              );
            })}
          </React.Fragment>
        );
      };

      const clearUsersPath = (err) => {
          setUsersPath([])
          setSelectedPath([])
          setError(err)

      }

      const formRegex = /complete-form/g;

    return (
      <>
       {/* {backButton()} */}
        <section className="md:mt-2 mt-5   s-decision-tree">
       
 <ul className="custom-width py-6 flex flex-col items-center" id="overflowScroll">
          {questionList()}
          <li
           
          />
        </ul>
        {usersPath.some(string => /complete-form/g.test(string)) ? (
          <Form usersPath={usersPath} clearPath={clearUsersPath} />
        ) : null}
        {usersPath.length === 0 && <Thanks error={error} />}
       
        </section>
        </>

    )
}

export default DecisionTree