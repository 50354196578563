import React from "react";
import Result from "./Result";
import Question from "./Question";
import Options from "./Options";
import ProgressBar from "./ProgressBar";




const QAContainer = (props) => {
  


    return(
<li
        tabIndex="-1"
      className="md:w-full listHeight"
      
      >
        {props.qablock && props.qablock.leaf ? (
          <Result result={props.qablock && props.qablock.result} link={props.qablock && props.qablock.link} linkText={props.qablock && props.qablock.linkText} />
        ) : (
          <React.Fragment>
<ProgressBar progressTitle={props.qablock && props.qablock.progressTitle} progressBar={props.qablock && props.qablock.progressBar} />
            <Question
              question={props.qablock && props.qablock.question && props.qablock.question}
              options={props.qablock &&props.qablock.options && props.qablock.options}
              link={props.qablock &&props.qablock.link}
              linkText={props.qablock &&props.qablock.linkText}
              selectedPath={props.qablock &&props.selectedPath}
            />
           
            <Options
            setUsersPath={props.setUsersPath}
              options={props.qablock &&props.qablock.options && props.qablock.options}
              clickHandler={props.qablock &&props.clickHandler}
              backScreen={props.qablock && props.qablock.backScreen}
              finalScreen={props.qablock && props.qablock.finalScreen}
              selectedPath={props.qablock &&props.selectedPath}
            />

            {props.qablock &&props.qablock.options && props.qablock.options.length > 1 &&
            <React.Fragment>
         
            </React.Fragment>
            }
          </React.Fragment>
        )}
      </li>
    )
}

export default QAContainer