import React, {useEffect} from "react";
import twitter from "../../../images/twitter.png"
import facebook from "../../../images/facebook.png"
import instagram from "../../../images/instagram.png"
import whatsapp from "../../../images/whatsapp.png"
import shareIcon from "../../../images/share-icon.png"
import Footer from "../../Footer";
import consorBankLogo from "../../../images/consorbank-logo.png";
import consorFrameLogo from "../../../images/consorbank-frame.png"
import SocialShare from "../SocialShare";
import ReactGA from 'react-ga';

const FinalScreen2 = () => {
  useEffect(() => {
    const element = document.getElementById("overflowScroll");
    element.classList.add("tabIndex");

  },[])

  // function to track click events of links and buttons in google analytics
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  // function to track page-view in google analytics
  ReactGA.pageview('/final-screen/discountbroker');

    return (
        <div className="mt-4">
        <p className="s-decision-tree__paragraph">
        Sie möchten Spaß haben, Zeit mit Ihren Investments verbringen und dabei für Ihre Entscheidungen Anregungen erhalten.
<br />
<br />
Das können Sie bei einem Discountbroker am kostengünstigsten umsetzen.
        </p>
      
      
      <p 
           className="s-decision-tree__link--dark mt-2 mb-4">
      Unsere Empfehlung
      </p>
      <div className="s-decision-tree__container">
        <img className="newLogo" src={consorBankLogo}/>

        <div className="mt-4">
            <a className="s-decision-tree__container__title" href="https://www.financeads.net/tc.php?t=46682C15212328T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Link", "Consorbank")}>Consorbank</a>
            <p className="s-decision-tree__container__subtitle">Günstiger Discountbroker</p>
        </div>


<p className="s-decision-tree__container__paragraph">
Die Consorbank hat rund 1,5 Millionen Kunden in Deutschland und gehört zu den führenden Direktbanken Europas.</p><div className="mt-8 md:flex justify-between items-center">

<div className="">
<img className="newFrame" src={consorFrameLogo}/>

</div> 

      <div className="mt-4 flex justify-between items-center">
  
  <a 
      className="s-decision-tree__btn md:p-0 p-4 btnLink" href="https://www.financeads.net/tc.php?t=46682C15212328T" target="_blank" onClick={eventTrack.bind(this, "Final Screen", "Partner-Button", "Consorbank")}>
    Zum Anbieter
  </a>
  
  </div>
  </div>
      </div>

      
      <div className="mt-8">
      <div className="mb-4">
        <h3 className="s-decision-tree__link--dark mb-4">Tipps</h3>
        <p className="s-decision-tree__paragraph mt-4">
        Consorsbank bietet mit dem Trader Depot und dem Finanz Coach Tools an, die Anregungen für Investments geben.
<br />
<br />
Obwohl der Momentum Effekt wissenschaftlich nachweisbar ist, bleibt es ambitioniert eine Mehrrendite daraus zu generieren. 
<br />
<br />
Seien Sie konsequent: Setzen Sie feste Verlustgrenzen und halten Sie diese strikt ein. 
<br />
<br />
Seien Sie ehrlich: Legen Sie einen objektiven Erfolgsmaßstab fest und prüfen Sie den Erfolg Ihrer Strategie.  </p>       
          </div>

          <div className="mb-8">
        <h3 className="s-decision-tree__link--dark">Was sagt die Risikoforschung?</h3>

          <p className="s-decision-tree__paragraph mt-4">
          Karl Valentin: "Prognosen sind schwierig, besonders wenn sie die Zukunft betreffen". Das ist wohl auch für den Aktienmarkt zutreffend.
<br />
<br />
In der Risikoforschung gibt es bei "Entscheidungen unter Unsicherheit" die Ausprägung "Risiko" - hier sind Eintritts-Wahrscheinlichkeiten bekannt - und "Ungewissheit" - hier ist nicht alles bekannt und die beste Option lässt sich nicht berechnen. 
<br />
<br />
Da der Momentum-Effekt wissenschaftlich nachgewiesen ist, gibt es durch logisches und statistisches Denken auch gute Entscheidungen, die ertragswirksam umgesetzt werden können.  </p>       
   
          </div>



     
          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt der Verbraucherschutz?</h3>
        <p className="s-decision-tree__paragraph mt-2">
        Der Verbraucherschutz rät grundsätzlich von Einzelaktien ab und empfielt börsengehandelte Fonds (ETF). Als Strategien werden hier langfristige und passive Konzepte empfohlen.

  </p>
         <a href="https://www.finanztip.de/aktien/aktien-kaufen/" target="_blank" className="s-decision-tree__link mt-4  text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/aktien/aktien-kaufen/")}>Mehr Informationen</a> 
          </div>

          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark" >Was sagt die unabhängige Bewertung?</h3>
        <p className="s-decision-tree__paragraph mt-2">
        Lesen Sie selbst!        </p>
         <a href="https://www.finanztip.de/wertpapierdepot/" target="_blank" className="s-decision-tree__link mt-4  text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/wertpapierdepot/")}>Mehr Informationen</a> 
      
          </div>



          <div className="mb-4">
        <h3 className="s-decision-tree__link--dark">Was sagt Villiam?</h3>
      <p className="s-decision-tree__paragraph mt-2">
      Die gewählte Strategie ist spannend und kann Spaß machen. Wichtig ist den Zeitaufwand und den Erfolg nicht aus den Augen zu verlieren. Als objektiven Erfolgsmaßstab empfehlen wir für 100.-Euro <a href="https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true" target="_target" className="s-decision-tree__link text-xs" style={{fontSize: "16px", textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.ishares.com/de/privatanleger/de/produkte/251850/ishares-msci-acwi-ucits-etf?switchLocale=y&siteEntryPassthrough=true")}>einen ETF </a>in das Depot zu legen. Es wird sehr schwer werden, auf lange Sicht erfolgreicher als der ETF zu sein.

</p>  
<a href="https://www.finanztip.de/indexfonds-etf/msci-world-etf-vergleich/" target="_blank" className="s-decision-tree__link  mt-4 text-base" style={{textDecoration: "underline"}} onClick={eventTrack.bind(this, "Final Screen", "Info-Link", "https://www.finanztip.de/indexfonds-etf/msci-world-etf-vergleich/")}>Mehr Informationen</a> 

          </div>




      </div>

   <SocialShare />
     
   <div className="relative md:mb-0 mb-10">
     <Footer final={true}/>
     </div>
  
              </div>

    )
}

export default FinalScreen2